.block.listing.homepagesliderview {
  margin-bottom: 0px !important;
  height: auto;
  min-height: 500px;
  max-height: 900px;
  height: 70vh;
}
@media screen and (min-width: 768px) {
  .block.listing.homepagesliderview {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .block.listing.homepagesliderview {
    max-height: 90vh;
  }
}
.view-viewview .block.listing.homepagesliderview {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  margin-bottom: 4rem !important;
}
.homepage-slider-template {
  min-height: 500px;
  max-height: 900px;
  height: 90vh;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .homepage-slider-template {
    max-height: 90vh;
  }
}
.homepage-slider-template .listing-slider {
  height: 100%;
}
.homepage-slider-template .listing-slider img {
  width: 100vw;
  height: 70vh;
  max-height: 900px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  min-height: 500px;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .listing-slider img {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .homepage-slider-template .listing-slider img {
    max-height: 90vh;
  }
}
.homepage-slider-template .content-wrapper {
  position: relative;
  height: 100%;
}
.homepage-slider-template .buttons {
  position: absolute;
  top: calc(50% - 115px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.homepage-slider-template .buttons button {
  margin-left: 7px;
  margin-right: 7px;
}
.homepage-slider-template .buttons .right-button,
.homepage-slider-template .buttons .left-button {
  background-color: rgba(36, 36, 36, 0.6);
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.homepage-slider-template .buttons .right-button {
  right: 0;
}
.homepage-slider-template .buttons .left-button {
  left: 0;
}
@media screen and (min-width: 662px) {
  .homepage-slider-template .buttons {
    top: calc(50% - 40px);
  }
}
.homepage-slider-template .buttons .left-arrow,
.homepage-slider-template .buttons .right-arrow {
  border: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: rgba(36, 36, 36, 0.6);
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  outline: none;
}
.homepage-slider-template .plone-item-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0%;
  width: auto;
  overflow: hidden;
}
.homepage-slider-template .plone-item-card-link {
  margin-left: auto !important;
  margin-right: auto !important;
  text-decoration: none !important;
  max-height: 100%;
  overflow: hidden;
}
.homepage-slider-template .plone-item-card-link > * {
  margin-left: auto !important;
  margin-right: auto !important;
}
.homepage-slider-template button > .icon {
  background-color: transparent;
}
.homepage-slider-template .left-button {
  margin-top: -6px;
  margin-left: -8px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 470px) {
  .homepage-slider-template .left-button {
    margin-top: -10px;
    margin-left: 27px;
  }
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .left-button {
    margin-top: 2px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .left-button {
    margin-top: 1px;
  }
}
.homepage-slider-template .left-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.homepage-slider-template .right-button {
  margin-top: -6px;
  margin-right: -8px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 470px) {
  .homepage-slider-template .right-button {
    margin-top: -10px;
    margin-left: 27px;
    margin-right: 21px;
  }
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .right-button {
    margin-top: 2px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .right-button {
    margin-top: 1px;
  }
}
.homepage-slider-template .right-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.homepage-slider-template .title-wrapper {
  position: absolute;
  height: 200px;
  bottom: 8px;
  width: 100vw;
  padding: 0%;
  margin: 0%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%) !important;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .title-wrapper {
    height: 300px;
  }
}
.homepage-slider-template .title-description {
  margin-top: 10px;
  position: absolute;
  bottom: 30px;
  left: 18px;
}
@media screen and (min-width: 662px) {
  .homepage-slider-template .title-description {
    left: 95px;
  }
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .title-description {
    width: calc(100% - 250px);
  }
}
.homepage-slider-template .title-description .hero.event-label span {
  position: relative;
  display: inline-block;
  width: auto;
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 15px;
  background-color: #008da9;
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 50px;
}
.homepage-slider-template .title-description .hero.event-label span:after {
  display: none !important;
}
.homepage-slider-template .title-description .hero.event-label span.future {
  background-color: #008da9;
}
.homepage-slider-template .title-description .hero.event-label span.future:after {
  border-left: 44px solid #008da9;
}
.homepage-slider-template .title-description .hero.event-label span.current {
  font-family: 'IntroBold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: #008da9;
}
.homepage-slider-template .title-description .hero.slide-title {
  margin-bottom: 0;
  font-weight: 800;
  max-width: 750px;
  font-family: 'IntroBold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 80px;
  line-height: 80px;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: left;
  color: #ffffff !important;
}
@media (max-width: 992px) {
  .homepage-slider-template .title-description .hero.slide-title {
    font-size: 39px;
    line-height: 34.32px;
    margin-top: 15px;
  }
}
.homepage-slider-template .title-description .hero.page.slide-title {
  padding: 0 !important;
}
.homepage-slider-template .title-description .slide-description {
  color: #ffffff !important;
}
.homepage-slider-template .title-description .hero.slide-description {
  font-size: 22px;
  line-height: 33px;
  color: #ffffff !important;
  font-family: 'IntroCondRegular', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  letter-spacing: normal !important;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.homepage-slider-template .plone-item-title {
  margin-top: 15px;
  margin-bottom: 5px;
}
.homepage-slider-template .plone-item-title p {
  font-size: 20px;
  color: #df0934;
  line-height: 28px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .plone-item-title p {
    font-size: 1.8vw;
    line-height: 2.5vw;
    color: #ffffff;
  }
}
@media screen and (min-width: 1480px) {
  .homepage-slider-template .plone-item-title p {
    font-size: 30px;
  }
}
.homepage-slider-template .item-description,
.homepage-slider-template .item-description span {
  font-size: 15px;
  color: #df0934;
  line-height: 25px;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .item-description,
  .homepage-slider-template .item-description span {
    color: #ffffff;
    font-size: 17px;
    line-height: 25px !important;
  }
}
.homepage-slider-template .slide-title-date {
  text-decoration: none !important;
  font-size: 1.18em;
  line-height: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .slide-title-date {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .slide-title-date {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
.homepage-slider-template .slide-title {
  text-decoration: none !important;
  font-size: 39px !important;
  line-height: 34.32px !important;
  margin-bottom: 32px;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .slide-title {
    font-size: 80px !important;
    line-height: 80px !important;
  }
}
.homepage-slider-template .slide-title:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 5px !important;
  text-underline-offset: 5px !important;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons svg:hover {
  fill: #df0934;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons .right-button {
  opacity: 0.9 !important;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons .left-button {
  opacity: 0.9 !important;
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 65vw !important;
}
.view-editview .block-editor-listing .homepage-slider-template.hero,
.view-editview .block-editor-listing .homepage-slider-template.hero img {
  max-width: 65vw !important;
}
.view-editview p.items-preview {
  display: none;
}
.hero.homepage-slider-template {
  margin-top: -2rem;
  margin-bottom: 4rem !important;
}
